<script>
const sidebarMenu = [

  { path: '/Profile', icon: 'fa fa-medkit', title: 'Mi Perfil'},

  { path: '/Persona', icon: 'fa fa-medkit', title: 'Paramétricas Ejto.',
    children: [
      { path: '/Persona', title: 'Persona' },
      { path: '/Rol', title: 'Rol' },
      { path: '/Departamento', title: 'Departamento' },
      { path: '/Fuerza', title: 'Fuerza' },
      { path: '/Especialidad', title: 'Especialidad' },
      { path: '/Sexo', title: 'Sexo' },
      { path: '/GrupoSanguineo', title: 'Grupo Sanguineo' },
      { path: '/EstadoCivil', title: 'Estado Civil' },
      { path: '/Escalafon', title: 'Escalafon' },
      { path: '/Cargo', title: 'Cargo' },
      { path: '/Grado', title: 'Grado' },
      { path: '/Reparticion', title: 'Reparticion' },
      { path: '/Arma', title: 'Arma' }
    ]
  },
  { path: '/Parametro', icon: 'fa fa-medkit', title: 'Paramétricas',
    children: [
      { path: '/Parametro', title: 'Parámetros Gral.' },
      { path: '/UnidadMedida', title: 'Unidad de Medida' },
      { path: '/Categoria', title: 'Categoría de Produccion' },
      { path: '/TipoProduccion', title: 'Tipo de Produccion' },
      { path: '/EstadoProduccion', title: 'Estado de Produccion' },
      { path: '/RazonPerdida', title: 'Razones de Perdida' },
      { path: '/TipoInspeccion', title: 'Tipo Inspeccion' },
      { path: '/EstadoEntrega', title: 'EstadoEntrega' },
      { path: '/CategoriaGasto', title: 'Categoria de Gasto' },
    ]
  },

  { path: '/dashboard/v2', icon: 'fa fa-chart-bar', title: 'Tablero de Control'},
  { path: '/dashboard/v1', icon: 'fa fa-chart-bar', title: 'Tablero de Control O'},
  { path: '/Producto', icon: 'fa fa-medkit', title: 'Productos'},
  { path: '/TipoTamano',icon: 'fa fa-medkit', title: 'Tipo Tamaño' },
  { path: '/Produccion', icon: 'fa fa-medkit', title: 'Producción'},
  { path: '/Almacenamiento', icon: 'fa fa-medkit', title: 'Almacenamiento'},
  { path: '/Cliente', icon: 'fa fa-medkit', title: 'Cliente'},
  { path: '/Precio', icon: 'fa fa-medkit', title: 'Precios'},
  { path: '/Venta', icon: 'fa fa-medkit', title: 'Venta'},
  { path: '/Recibo', icon: 'fa fa-medkit', title: 'Recibos'},
  { path: '/Inspeccion', icon: 'fa fa-medkit', title: 'Inspecciones'},
  { path: '/Proveedor', icon: 'fa fa-medkit', title: 'Proveedores'},
  { path: '/Entrega', icon: 'fa fa-medkit', title: 'Entregas'},
  { path: '/Perdida', icon: 'fa fa-medkit', title: 'Perdidas'},
  { path: '/Gasto', icon: 'fa fa-medkit', title: 'Gastos'},
]

export default sidebarMenu;
</script>
