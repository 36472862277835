<template>
	<div>
		<h1 class="page-header">TABLERO DE CONTROL <small>Porcentaje de Postura y Producción Diaria</small></h1>

		<!-- Gráficos de Producción Diaria (Cantidad) -->
		<div class="row">
			<div class="col-xl-12">
				<div class="widget-chart-content bg-dark">
					<h4 class="chart-title">
						
						<small> CANTIDAD PRODUCIDA SEPTIEMBRE Cantidad diaria producida</small>
					</h4>
					<div class="widget-chart-full-width nvd3-inverse-mode" style="height: 260px">
						<vn-stacked-area :model="septemberChart.data" yFormat=",.0f" :xFormat="formatDate"
							:clipEdge="septemberChart.clipEdge" :rightAlignYAxis="septemberChart.rightAlignYAxis"
							:showControls="septemberChart.showControls"></vn-stacked-area>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xl-12">
				<div class="widget-chart-content bg-dark">
					<h4 class="chart-title">
						
						<small>CANTIDAD PRODUCIDA OCTUBRE Cantidad diaria producida</small>
					</h4>
					<div class="widget-chart-full-width nvd3-inverse-mode" style="height: 260px">
						<vn-stacked-area :model="octoberChart.data" yFormat=",.0f" :xFormat="formatDate"
							:clipEdge="octoberChart.clipEdge" :rightAlignYAxis="octoberChart.rightAlignYAxis"
							:showControls="octoberChart.showControls"></vn-stacked-area>
					</div>
				</div>
			</div>
		</div>

		<!-- Gráficos de Porcentaje de Postura -->
		<div class="row">
			<div class="col-xl-12">
				<div class="widget-chart-content bg-dark">
					<h4 class="chart-title">
						<small>PORCENTAJE DE POSTURA SEPTIEMBRE Porcentaje de postura diario</small>
					</h4>
					<div class="widget-chart-full-width nvd3-inverse-mode" style="height: 260px">
						<vn-stacked-area :model="septemberPosturaChart.data" yFormat=",.2f" :xFormat="formatDate"
							:clipEdge="septemberPosturaChart.clipEdge" :rightAlignYAxis="septemberPosturaChart.rightAlignYAxis"
							:showControls="septemberPosturaChart.showControls"></vn-stacked-area>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xl-12">
				<div class="widget-chart-content bg-dark">
					<h4 class="chart-title">
						
						<small> PORCENTAJE DE POSTURA OCTUBRE Porcentaje de postura diario</small>
					</h4>
					<div class="widget-chart-full-width nvd3-inverse-mode" style="height: 260px">
						<vn-stacked-area :model="octoberPosturaChart.data" yFormat=",.2f" :xFormat="formatDate"
							:clipEdge="octoberPosturaChart.clipEdge" :rightAlignYAxis="octoberPosturaChart.rightAlignYAxis"
							:showControls="octoberPosturaChart.showControls"></vn-stacked-area>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MainService from "@/services/MainService.js";  // Servicio para la API

export default {
	name: 'DashboardV2',
	data() {
		return {
			// Gráficos para cantidad producida (como ya tienes)
			septemberChart: {
				data: [],
				rightAlignYAxis: false,
				showControls: false,
				clipEdge: false
			},
			octoberChart: {
				data: [],
				rightAlignYAxis: false,
				showControls: false,
				clipEdge: false
			},
			// Gráficos para porcentaje de postura
			septemberPosturaChart: {
				data: [],
				rightAlignYAxis: false,
				showControls: false,
				clipEdge: false
			},
			octoberPosturaChart: {
				data: [],
				rightAlignYAxis: false,
				showControls: false,
				clipEdge: false
			},
			ss: new MainService(),
			isLoading: false
		};
	},
	components: {
	},
	mounted() {
		// Llamada a la API para obtener los datos al montar el componente
		this.fetchData();
	},
	methods: {
		fetchData() {
			this.isLoading = true;
			this.ss.reportprod()
				.then((result) => {
					const produccion = result.data.data;

					// Filtrar por mes (Septiembre) - Cantidad producida
					const septemberData = produccion.filter(item => {
						const date = new Date(item.Fecha);
						return date.getMonth() === 8;
					}).map(item => ({
						x: new Date(item.Fecha).getTime(),
						y: parseFloat(item.CantidadProducida)
					}));

					// Filtrar por mes (Octubre) - Cantidad producida
					const octoberData = produccion.filter(item => {
						const date = new Date(item.Fecha);
						return date.getMonth() === 9;
					}).map(item => ({
						x: new Date(item.Fecha).getTime(),
						y: parseFloat(item.CantidadProducida)
					}));

					// Asignar los datos a los gráficos de cantidad producida
					this.septemberChart.data = [
						{
							key: 'Cantidad Producida',
							values: septemberData,
							color: '#5AC8FA'
						}
					];

					this.octoberChart.data = [
						{
							key: 'Cantidad Producida',
							values: octoberData,
							color: '#5AC8FA'
						}
					];

					// Filtrar por mes (Septiembre) - Porcentaje de postura
					const septemberPosturaData = produccion.filter(item => {
						const date = new Date(item.Fecha);
						return date.getMonth() === 8;
					}).map(item => ({
						x: new Date(item.Fecha).getTime(),
						y: parseFloat(item.PorcentajePostura)
					}));

					// Filtrar por mes (Octubre) - Porcentaje de postura
					const octoberPosturaData = produccion.filter(item => {
						const date = new Date(item.Fecha);
						return date.getMonth() === 9;
					}).map(item => ({
						x: new Date(item.Fecha).getTime(),
						y: parseFloat(item.PorcentajePostura)
					}));

					// Asignar los datos a los gráficos de porcentaje de postura
					this.septemberPosturaChart.data = [
						{
							key: 'Porcentaje de Postura',
							values: septemberPosturaData,
							color: '#FFB74D'
						}
					];

					this.octoberPosturaChart.data = [
						{
							key: 'Porcentaje de Postura',
							values: octoberPosturaData,
							color: '#FFB74D'
						}
					];

					this.isLoading = false;
				})
				.catch((error) => {
					console.error("Error al obtener los datos:", error);
					this.isLoading = false;
				});
		},
		formatDate(d) {
			const date = new Date(d);
			return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
		}
	}
};
</script>
